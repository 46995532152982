(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/youtube-video/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/components/youtube-video/assets/javascripts/utils.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const isServer = typeof exports === 'object';
const youtubeDomain = "https://www.youtube-nocookie.com";
const ytImgDomain = "https://i.ytimg.com/vi";
const webPImgDomain = "https://i.ytimg.com/vi_webp";
const trinidad = isServer ? require('trinidad-core').core : {};
const {
  getJsonLDString
} = isServer ? trinidad.components.require('utils', 'get-json-ld') : svs.utils;
function loadVideo(options, callback) {
  if (!options) {
    callback('Options object is required');
    return;
  }
  if (!options.type || options.type !== 'youtube') {
    callback('Unsupported media source type');
    return;
  }
  if (!options.videoId) {
    callback('Missing videoId');
    return;
  }
  const {
    videoId,
    height,
    width,
    title,
    type,
    name,
    publishedAt,
    thumbnail
  } = options;
  const videoSrc = "".concat(youtubeDomain, "/embed/").concat(videoId, "?rel=0");
  const thumbnailUrl = thumbnail || "".concat(ytImgDomain, "/").concat(videoId);
  const webpThumbnailUrl = thumbnail || "".concat(webPImgDomain, "/").concat(videoId);
  const model = {
    name: name || '100',
    type: type || 'youtube',
    src: videoSrc,
    publishedAt,
    heightPercent: height && width ? height / width * 100 : undefined,
    videoId,
    thumbnailUrl,
    title: title || 'Svenska Spel Video',
    height: height || '1280',
    width: width || '720',
    webpThumbnailUrl
  };
  const jsonLDTags = getJsonLDString({
    duration: options === null || options === void 0 ? void 0 : options.duration,
    description: options === null || options === void 0 ? void 0 : options.description,
    uploadDate: publishedAt,
    name: model.title,
    thumbnailUrl
  });
  callback(null, _objectSpread(_objectSpread({}, model), {}, {
    jsonLDTags
  }));
}
if (isServer) {
  module.exports = {
    loadVideo
  };
} else {
  svs.components.youtube_video.utils = {
    loadVideo
  };
}

 })(window);