(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/youtube-video/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/components/youtube-video/assets/javascripts/init.js');

'use strict';

const logger = svs.core.log.getLogger('component:youtube-video_init');
const {
  trackEvent
} = svs.components.analytics;
const YOUTUBE_URL = 'https://www.youtube-nocookie.com';

svs.components.youtube_video.init = function (options) {
  if (!options.videoId) {
    logger.warn('Missing videoId');
    return;
  }
  if (options && options.selector && options.src) {
    const videoHtml = svs.components.youtube_video.templates.youtube_video(options);
    $(options.selector).append(videoHtml);
  } else {
    logger.warn('Error in options object', options);
  }
};
const iframes = document.querySelectorAll('.js-youtube-video-iframe');
const videoStates = new Map();
function sendTrackEvent(action, category, videoId, videoTitle, optValue) {
  trackEvent({
    action,
    category,
    opt_label: "".concat(videoId, " - ").concat(videoTitle),
    opt_value: optValue
  });
}
iframes.forEach(iframe => {
  iframe.contentWindow.postMessage('{"event":"listening","channel":"widget"}', '*');
});
window.addEventListener('message', evt => {
  if (evt.origin === YOUTUBE_URL) {
    let data;
    try {
      data = JSON.parse(evt.data);
    } catch (e) {
      logger.warn('Failed to parse message data:', e);
      return;
    }

    if (data && data.info && typeof data.info.playerState === 'number') {
      var _data$info$videoData, _data$info$videoData2;
      const videoId = (_data$info$videoData = data.info.videoData) === null || _data$info$videoData === void 0 ? void 0 : _data$info$videoData.video_id;
      const videoTitle = (_data$info$videoData2 = data.info.videoData) === null || _data$info$videoData2 === void 0 ? void 0 : _data$info$videoData2.title;
      if (data.info.playerState === 1) {
        if (!videoStates.has(videoId)) {
          sendTrackEvent('Video start-youtube', 'Youtube video', videoId, videoTitle, 1);

          const timer = setTimeout(() => {
            sendTrackEvent('Video progress-youtube', 30, videoId, videoTitle, 1);
            videoStates.delete(videoId);
          }, 30000);
          videoStates.set(videoId, {
            timer,
            startTime: Date.now(),
            accumulatedTime: 0
          });
        } else {
          const videoState = videoStates.get(videoId);
          videoState.startTime = Date.now();

          if (!videoState.timer) {
            const remainingTime = 30000 - videoState.accumulatedTime * 1000;
            const timer = setTimeout(() => {
              sendTrackEvent('Video progress-youtube', 30, videoId, videoTitle, 1);
              videoStates.delete(videoId);
            }, remainingTime);
            videoState.timer = timer;
          }
        }
      } else if (data.info.playerState === 2 || data.info.playerState === 0) {
        const videoState = videoStates.get(videoId);
        if (videoState) {
          const currentTime = Date.now();
          const playTime = (currentTime - videoState.startTime) / 1000; 
          videoState.accumulatedTime += playTime;
          if (videoState.accumulatedTime >= 30) {
            sendTrackEvent('Video progress-youtube', 30, videoId, videoTitle, 1);
            videoStates.delete(videoId);
          } else {
            clearTimeout(videoState.timer);
            videoState.timer = null;
            videoStates.set(videoId, videoState);
          }
        }
      }
    }
  }
});

 })(window);